/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.book-appointment-stepper {
    .mat-step-header {
        &.cdk-focused,
        &.cdk-touch-focused,
        &:hover:not([aria-disabled]),
        &:hover[aria-disabled='false'] {
            background-color: transparent !important;
            box-shadow: none !important;
        }
    }
}
