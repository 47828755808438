/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.book-appointment-stepper {
    .mat-step-header {
        &.cdk-focused,
        &.cdk-touch-focused,
        &:hover:not([aria-disabled]),
        &:hover[aria-disabled='false'] {
            background-color: transparent !important;
            box-shadow: none !important;
        }
    }
}

.custom-snackbar {
    margin-bottom: 28px !important;
    border-radius: 16px !important;
    --mdc-snackbar-container-color: #fff !important;
    --mdc-snackbar-supporting-text-color: #000 !important;
}

.dark .custom-snackbar {
    --mdc-snackbar-container-color: #0f172a !important;
    --mdc-snackbar-supporting-text-color: #fff !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    border-radius: 16px !important;

    box-shadow: none !important;
}

.custom-snackbar-error-shadow {
    // box-shadow: 0px 20px 20px -17px rgba(255, 16, 39, 0.5) !important;
    box-shadow:
        0 10px 15px 0px rgba(239, 68, 68, 0.4),
        0 4px 6px 0px rgba(239, 68, 68, 0.25) !important;
}

.dark .custom-snackbar-error-shadow {
    box-shadow:
        0 10px 15px 0px rgba(239, 68, 68, 0.1),
        0 4px 6px 0px rgba(239, 68, 68, 0.05) !important;
}

.custom-snackbar-success-shadow {
    box-shadow:
        0 10px 15px 0px rgba(34, 197, 94, 0.4),
        0 4px 6px 0px rgba(34, 197, 94, 0.25) !important;
}

.dark .custom-snackbar-success-shadow {
    box-shadow:
        0 10px 15px 0px rgba(34, 197, 94, 0.1),
        0 4px 6px 0px rgba(34, 197, 94, 0.05) !important;
}

.custom-snackbar-warning-shadow {
    box-shadow:
        0 10px 15px 0px rgba(245, 158, 11, 0.4),
        0 4px 6px 0px rgba(245, 158, 11, 0.25) !important;
}

.dark .custom-snackbar-warning-shadow {
    box-shadow:
        0 10px 15px 0px rgba(245, 158, 11, 0.1),
        0 4px 6px 0px rgba(245, 158, 11, 0.05) !important;
}
