.mat-autocomplete-panel {
    max-height: 300px; /* Adjust as needed */
    overflow: auto;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0;
}

@media (max-width: 768px) {
    .mat-mdc-paginator .mat-mdc-paginator-container {
        padding: 0; /* Elimina el padding en móviles */
    }
}
